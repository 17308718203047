import _sum from "lodash/sum";
import _tail from "lodash/tail";
import _map from "lodash/map";
import _isString from "lodash/isString";
import moment from 'moment';
export var constUndef = function constUndef() { return undefined; };
export var required = function required(value) { return value && (!_isString(value) || value.trim()) || value === false ? undefined : 'を入力してください'; };
export var requiredIf = function requiredIf(condition) { return condition ? required : constUndef; };
export var number = function number(value) { return value && !value.match(/^\d+$/) ? 'を半角数字で入力してください' : undefined; };
export var wage = function wage(value) { return value && !value.match(/^\d{1,7}$/) && value !== '未計算' ? 'は半角数値7桁以内か「未計算」と入力してください。test' : undefined; };
export var numberValue = function numberValue(value) { return value && !value.match(/^[+-]?\d+$/) ? 'は半角数字と「-（マイナス）」で入力してください。また、小数点は入力できません。' : undefined; };
export var floatValue = function floatValue(value) { return value && !value.match(/^[+-]?(\d+\.\d*|\d*\.\d+|\d+)(e[-+]{0,1}\d+)?$/i) ? 'は半角数字と「-（マイナス）」「.」で入力してください。' : undefined; };
var exactLengthNumberValidators = [];
export var exactLengthNumber = function exactLengthNumber(len) { if (!exactLengthNumberValidators[len]) {
    exactLengthNumberValidators[len] = function (value) { return len && value && value.length !== len ? "\u306F".concat(len, "\u6841\u306E\u534A\u89D2\u6570\u5B57\u3067\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044") : undefined; };
} return exactLengthNumberValidators[len]; };
var maxLengthNumberValidators = [];
export var maxLengthNumber = function maxLengthNumber(max) { if (!maxLengthNumberValidators[max]) {
    maxLengthNumberValidators[max] = function (value) { return value && value.length > max ? "\u306F\u534A\u89D2\u6570\u5B57".concat(max, "\u6841\u4EE5\u4E0B\u3067\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044") : undefined; };
} return maxLengthNumberValidators[max]; };
var maxLengthValidators = [];
export var maxLength = function maxLength(max) { if (!maxLengthValidators[max]) {
    maxLengthValidators[max] = function (value) { return value && value.length > max ? "\u3092".concat(max, "\u6587\u5B57\u4EE5\u5185\u3067\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044") : undefined; };
} return maxLengthValidators[max]; };
var minLengthValidators = [];
export var minLength = function minLength(min) { if (!minLengthValidators[min]) {
    minLengthValidators[min] = function (value) { return value && value.length < min ? "\u3092".concat(min, "\u6587\u5B57\u4EE5\u4E0A\u3067\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044") : undefined; };
} return minLengthValidators[min]; };
var maxNumberValidators = [];
export var maxNumber = function maxNumber(max) { if (!maxNumberValidators[max]) {
    maxNumberValidators[max] = function (value) { return value && +value > max ? "\u3092".concat(max, "\u4EE5\u5185\u3067\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044") : undefined; };
} return maxNumberValidators[max]; };
var minNumberValidators = [];
export var minNumber = function minNumber(min) { if (!minNumberValidators[min]) {
    minNumberValidators[min] = function (value) { return value && +value < min ? "\u3092".concat(min, "\u4EE5\u4E0A\u3067\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044") : undefined; };
} return minNumberValidators[min]; };
var maxAgeValidators = [];
export var maxAge = function maxAge(max) { if (!maxAgeValidators[max]) {
    maxAgeValidators[max] = function (value) { if (value) {
        var parsed = parseInt(value);
        if (number(value) || isNaN(parsed) || parsed < 0 || parsed > max) {
            return "\u306F0\u304B\u3089".concat(max, "\u307E\u3067\u306E\u534A\u89D2\u6570\u5B57\u3067\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044");
        }
    } return undefined; };
} return maxAgeValidators[max]; };
export var zenkakuKatakana = function zenkakuKatakana(value) { return value && !value.match(/^[\u30A0-\u30FF]+$/) ? 'を全角カタカナで入力してください' : undefined; };
export var email = function email(value) { return value && !value.match(/^([^@\s]+)@((?:[a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,})$/i) ? 'が正しくありません' : undefined; };
export var password = function password(value) { if (!value)
    return undefined; if (value.length < 8)
    return 'を8文字以上で入力してください'; if (value.length > 200)
    return 'を200文字以下で入力してください'; if (!value.match(/^[\x21-\x7e¥]+$/))
    return 'が正しくありません'; return undefined; };
export var romajiName = function romajiName(value) { return value && !value.match(/^[A-Z a-z-]+$/) ? 'を半角英字で入力してください' : undefined; };
export var zenkakuKatakanaWithSpace = function zenkakuKatakanaWithSpace(value) { return value && !value.match(/^[\u30A0-\u30FF\u3000 ]+$/) ? 'を全角カタカナとスペースで入力してください' : undefined; };
export var zenkakuKatakanaLoose = function zenkakuKatakanaLoose(value) { return value && value.match(/[\u4E00-\u9FFF\u3005-\u3006\u3040-\u309f]/) ? 'を全角カタカナとスペースで入力してください' : undefined; };
export var corporateNumber = function corporateNumber(value) { return value && !isCorporateNumber(value) ? 'が正しくありません' : undefined; };
export var staffCode = function staffCode(value) { return value && !value.match(/^[\w-_]+$/) ? 'は半角英数字と「-」「_」で入力してください。' : undefined; };
export var officeCode = function officeCode(value) { return value && !value.match(/^[A-Za-z0-9]*$/) ? 'は半角英数字で入力してください。' : undefined; };
export var visaCode = function visaCode(value) { return value && !value.match(/^[A-Za-z0-9]*$/) ? 'は半角英数字で入力してください。' : undefined; };
export var date = function date(value) { return value && !moment(value, 'YYYY/MM/DD').isValid() ? 'の入力形式が正しくありません' : undefined; }; // Format: YYYY/MM/DD or YYYY-MM-DD
export var dateString = function dateString(value) { if (!value)
    return undefined; if (!/^\d{4}[/-]\d{1,2}[/-]\d{1,2}$/.test(value)) {
    return 'の入力形式が正しくありません';
} return undefined; }; // http://www.houjin-bangou.nta.go.jp/documents/checkdigit.pdf
var isCorporateNumber = function isCorporateNumber(value) { if (!value.match(/^\d{13}$/)) {
    return false;
} var digits = _map(value, function (digit) { return +digit; }); var realDigits = _tail(digits); var oddSum = _sum(realDigits.filter(function (_, index) { return index % 2 === 1; })); var evenSum = _sum(realDigits.filter(function (_, index) { return index % 2 === 0; })); var checkDigit = 9 - (evenSum * 2 + oddSum) % 9; return digits[0] === checkDigit; };
var exactLengthValidators = [];
export var exactLength = function exactLength(len) { if (!exactLengthValidators[len]) {
    exactLengthValidators[len] = function (value) { return len && value && value.length !== len ? "\u306F".concat(len, "\u6587\u5B57\u3067\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044") : undefined; };
} return exactLengthValidators[len]; };
export var noHankaku = function noHankaku(value) { return value && value.match(/[ -~]+/) ? 'を全角で入力してください' : undefined; };
export var futureDate = function futureDate(value) { if (!value)
    return undefined; if (!moment(value, 'YYYY/MM/DD').isValid())
    return 'の入力形式が正しくありません'; if (moment(value).endOf('days').isBefore(moment())) {
    return 'に過去の日付を指定することはできません';
} return undefined; };
