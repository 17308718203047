function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o)
    return; if (typeof o === "string")
    return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor)
    n = o.constructor.name; if (n === "Map" || n === "Set")
    return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
    return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter))
    return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr))
    return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length)
    len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
} return arr2; }
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory as createHistory } from 'history';
import routerScroll from './routerScroll';
import rootReducer from './reducers';
import { createEpicMiddleware } from 'redux-observable';
import epics from './epics';
import trimFields from './trimFields';
var isDevelopmentEnv = process.env.NODE_ENV.startsWith('development');
var epicMiddleware = createEpicMiddleware();
export var history = createHistory();
var defaultMiddleware = [thunk, routerMiddleware(history), routerScroll, epicMiddleware, trimFields];
export default function configureStore(initialState) { var middleware = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : []; var composeEnhancers = isDevelopmentEnv && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; var store = createStore(rootReducer(history), initialState, composeEnhancers(applyMiddleware.apply(void 0, defaultMiddleware.concat(_toConsumableArray(middleware))))); epicMiddleware.run(epics); if (module.hot) {
    module.hot.accept('./reducers', function () { var nextRootReducer = require('./reducers').default; store.replaceReducer(nextRootReducer); });
} return store; }
