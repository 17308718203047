import _isEmpty from "lodash/isEmpty";
import Cookies from 'js-cookie';
import useReactRouter from 'use-react-router';
import { useSession } from '../SessionProvider';
var defaultLimit = '100';
var limitKey = function limitKey(pathname, user) { return "".concat(pathname.replace(/\//g, ''), "#").concat(user.id, "__disp_emp_limit"); };
export var getSavedDisplayEmployeeLimit = function getSavedDisplayEmployeeLimit(pathname, user) { if (window.localStorage) {
    var _limit = window.localStorage.getItem(limitKey(pathname, user));
    if (!_isEmpty(_limit)) {
        return _limit;
    }
} var limit = Cookies.get(limitKey(pathname, user)); return _isEmpty(limit) ? defaultLimit : limit; };
export var saveDisplayEmployeeLimit = function saveDisplayEmployeeLimit(pathname, user, limit) { if (window.localStorage) {
    window.localStorage.setItem(limitKey(pathname, user), limit);
}
else {
    Cookies.set(limitKey(pathname, user), limit);
} };
export var useDisplayEmployeeLimit = function useDisplayEmployeeLimit() { var _useReactRouter = useReactRouter(), pathname = _useReactRouter.location.pathname; var _useSession = useSession(), user = _useSession.user; var limit = getSavedDisplayEmployeeLimit(pathname, user); return [limit, function (limit) { return saveDisplayEmployeeLimit(pathname, user, limit); }]; };
