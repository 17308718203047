import _some from "lodash/some";
import _range from "lodash/range";
import _chunk from "lodash/fp/chunk";
import _reverse from "lodash/fp/reverse";
import _toArray from "lodash/fp/toArray";
import { toJpYearDate } from 'jbc-front/utils/jpYear';
import moment from 'moment';
import { pipe } from './pipe';
export var recordDisplay = function recordDisplay(text) { return text ? text : '―'; };
export var numberWithSplit = function numberWithSplit(num) { var numStr = "".concat(num !== null && num !== void 0 ? num : recordDisplay()); if (numStr.startsWith('-')) {
    return '-' + numberWithSplit(numStr.substr(1));
} return pipe(_toArray, _reverse, _chunk(3), _reverse)(numStr).map(function (arr) { return arr.reverse().join(''); }).join(','); };
export var recordDisplayYen = function recordDisplayYen(record) { if (!record) {
    return recordDisplay();
} return numberWithSplit(record) + '円'; };
export var recordDisplayDate = function recordDisplayDate(date) { var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'wareki'; if (!date) {
    return recordDisplay();
} if (format === 'wareki') {
    return toJpYearDate(date);
} if (format === 'seireki') {
    return moment(date).format('LL');
} return moment(date).format(format); };
export var recordDisplayNumbers = function recordDisplayNumbers(record, name, length) { var split = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '-'; if (!record) {
    return recordDisplay();
} var fields = _range(0, length).map(function (idx) { return record["".concat(name).concat(idx)]; }); if (_some(fields, function (field) { return !field; })) {
    return recordDisplay();
} return fields.join(split); };
export default recordDisplay;
